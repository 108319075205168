<template>
  <div class="bg-white box-shadow border-r px-20 pb-20">
    <div v-loading="loadingRecord">
      <div class="d-flex-center px-15 border-bottom">
        <h4 class="mr-auto color-333">{{ $t("account_details.router") }}</h4>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-refresh"
          plain
          @click="fatchRecord()"
          >{{ $t("base.refresh") }}</el-button
        >
      </div>

      <div>
        <el-row
          :gutter="20"
          type="flex"
          justify="center"
          align="middle"
          class="color-gray px-20 py-15 border-bottom font-14"
        >
          <el-col :span="6">{{ $t("account_details.span1") }}</el-col>
          <el-col :span="6">{{ $t("account_details.span2") }}</el-col>
          <el-col :span="6">{{ $t("account_details.span3") }}</el-col>
          <el-col :span="6" style="text-align:right;">{{ $t("account_details.span4") }}</el-col>
        </el-row>
        <el-row
          v-for="(item, index) in list"
          :key="index"
          :gutter="20"
          type="flex"
          justify="center"
          align="middle"
          class="px-20 py-15 border-bottom font-14"
        >
          <el-col :span="6">
            <span v-if="item.type == 0">{{$t("account_details.type0")}}</span>
            <span v-if="item.type == 1">{{$t("account_details.type1")}}</span>
            <span v-if="item.type == 2">{{$t("account_details.type2")}}</span>
            <span v-if="item.type == 3">{{$t("account_details.type3")}}</span>
            <span v-if="item.type == 4">{{$t("account_details.type4")}}</span>
            <span v-if="item.type == 5">{{$t("account_details.type5")}}</span>
            <span v-if="item.type == 6">{{$t("account_details.type6")}}</span>
            <span v-if="item.type == 7">{{$t("account_details.type7")}}</span>
          </el-col>
          <el-col :span="6">
            <span
              class="span-style"
              v-if="Number(item.state) == 0"
              style="color: #e6a23c"
            >
               {{$t("account_details.state0")}}
            </span>
            <span
              class="span-style"
              v-if="Number(item.state) == 1"
              style="color: #67c23a"
            >
              {{$t("account_details.state1")}}
            </span>
            <span
              class="span-style"
              v-if="Number(item.state) == 2"
              style="color: #f56c6c"
            >
              {{$t("account_details.state2")}}
            </span>
          </el-col>
          <el-col :span="6">
            <span
              class="span-style"
              v-if="Number(item.amount) == 0"
              style="color: #e6a23c"
            >
               {{item.amount}}
            </span>
            <span
              class="span-style"
              v-if="Number(item.amount) > 0"
              style="color: #67c23a"
            >
              {{item.amount}}
            </span>
            <span
              class="span-style"
              v-if="Number(item.amount) < 0"
              style="color: #f56c6c"
            >
              {{item.amount}}
            </span>
          </el-col>
          <el-col :span="6" style="text-align:right;">{{ item.update_time }}</el-col>
        </el-row>
        <div class="d-flex-center pt-20">
          <el-pagination
            :hide-on-single-page="true"
            background
            layout="prev, pager, next"
            :page-size="per_page"
            :current-page="current_page"
            :total="total"
            @current-change="onPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";
export default {
  data() {
    return {
      coinLogo,
      //   info: {
      //     open: false,
      //     auto: false,
      //     address: [
      //       {
      //         tag: "DEFAULT",
      //         address: "",
      //       },
      //     ],
      //   },

      loadingData: true,
      loadingRecord: false,
      loading: false,

      list: [],

      timer: null,

      current_page: 1,
      per_page: 10,
      total: 0,
    };
  },
  watch: {},
  created: function () {
    this.fatchRecord();
  },
  methods: {
    //充币记录
    fatchRecord: async function () {
      this.loadingRecord = true;
      try {
        const { data } = await this.$request.post("v1/contract/getWalletLog", {
          page: this.current_page,
          pageSize: this.per_page,
        });
        this.list = data.list;
        this.total = data.total;
        this.loadingRecord = false;
      } catch (error) {
        this.loadingRecord = false;
      }
    },

    onPage(page) {
      this.current_page = page;
      this.fatchRecord();
    },
  },
};
</script>
<style lang="scss" scoped>
.input-form {
  width: 400px;
}
.voucher-img {
  width: 360px;
  height: 270px;
}
.no-authority {
  padding: 120px 0;
  box-sizing: border-box;
  text-align: center;
}
</style>